import React from 'react';

const Photos: React.FC = () => {
  return (
    <div className="page-photos-container container">
      FOTOS
    </div>
  );
}

export default Photos;
